import styled from 'styled-components'
import PlatformBadges from './PlatformBadges'
import Link from 'next/link'

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px;
`

const FooterLink = props => (
  <Link {...props}><a style={{ marginRight: 10 }}>{props.children}</a></Link>
)

const Footer = () => {
  return (
    <FooterContainer>
      <p>Game & site created by Adam Soutar</p>

      <p>
        <FooterLink href='https://status.mastermine.app/'>Server Status</FooterLink>
        <FooterLink href='/privacy-policy'>Privacy Policy</FooterLink>
        <FooterLink href='/changelog'>Changelog</FooterLink>
      </p>

      <PlatformBadges />
    </FooterContainer>
  )
}

export default Footer
