// This is an error boundary (currently only for the 3d content)
// react-three-fiber uses ResizeObserver which is not supported on old iOS
// devices. Instead of unmounting the entire page or using a slow polyfill,
// we just don't render the 3D cube.

// NOTE: StandardJS is disabled for this file because es-lint can't parse the
//       definition style I used for state in the class component

import { Component } from 'react'

class ErrorBoundary3d extends Component {
  state = { render3d: true }
  static getDerivedStateFromError(e) {
    return { render3d: false }
  }

  render() {
    if (!this.state.render3d) {
      return (
        <>
          <p>There's supposed to be an awesome 3d cube here...</p>
          <p>But your browser does not support features necessary to render 3d.</p>
          <p>Please update your browser/system software.</p>
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary3d
