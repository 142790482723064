import Image from 'next/image'
import NewTabLink from './NewTabLink'
import config from '../config'
import styled from 'styled-components'

// Next is deprecating named imports from JSON files for some reason
const { appStoreURL, playStoreURL, pcStoreURL } = config

const BadgeHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  /* NOTE: Safari (iOS & Mac) doesn't support gap */

  @media (max-width: 700px) {
    gap: 2px;
  }
`

const PlatformBadges = () => {
  return (
    <BadgeHolder>
      <NewTabLink href={appStoreURL} aria-label='App Store'>
        <Image
          src='/img/badge-app-store.svg'
          alt='App Store badge'
          width={179}
          height={60}
          layout='fixed'
        />
      </NewTabLink>

      <NewTabLink href={pcStoreURL} aria-label='Desktop download'>
        <Image
          src='/img/badge-desktop.png'
          alt='Desktop badge'
          width={201}
          height={60}
          layout='fixed'
        />
      </NewTabLink>

      <NewTabLink href={playStoreURL} aria-label='Google Play'>
        <Image
          src='/img/badge-google-play.png'
          alt='Google Play badge'
          width={201}
          height={60}
          layout='fixed'
        />
      </NewTabLink>
    </BadgeHolder>
  )
}

export default PlatformBadges
