import styled from 'styled-components'
import Link from 'next/link'

const PAGES = [
  ['Home', '/'],
  ['Skins', '/skins'],
  ['FAQ', '/faq'],
  ['Contact', '/contact']
]

const MenuStyled = styled.div`
  position: fixed;
  top: 0; right: 0;
  background: rgb(236, 236, 236);
  border-bottom-left-radius: 20px;
  padding: 20px 0 20px 20px;
  z-index: 2;
`
const ResponsivePusher = styled.div`
  @media (max-width: 700px) {
    height: 60px;
  }
`

const Menu = () => {
  return (
    <>
      <ResponsivePusher />
      <MenuStyled>
        {
          PAGES.map(p => (
            <Link href={p[1]} key={p[1]}>
              <a style={{ marginRight: 20 }}>{p[0]}</a>
            </Link>
          ))
        }
      </MenuStyled>
    </>
  )
}

export default Menu
