import styled from 'styled-components'
import Image from 'next/image'
import Link from 'next/link'

const Section = styled.div`
  padding: 30px;
  background-color: rgba(0,0,0,0.05);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 150px;
`
const Half = styled.div`
  width: 100%; max-width: 500px;
`

const MainDescription = () => {
  return (
    <>
      <Section>
        <Half>
          <h2>A classic reimagined</h2>

          <p>
            Mastermine brings the classic puzzle of Minesweeper into another
            dimension with all new graphics & gameplay.
          </p>

          <p>
            As if that wasn't enough, new "powerups" can help you out in a pinch
            by blasting away parts of the cube, shuffling mine locations, probing
            for intel with sonar and more.
          </p>
        </Half>

        <Half>
          <Image
            src='/img/5x5Cube.png'
            alt='A 5x5 cube'
            width={512}
            height={512}
            quality={100}
          />
        </Half>
      </Section>

      <Section>
        <Half>
          <Image
            src='/img/5x5Retro.png'
            alt='A 5x5 cube with a retro skin'
            width={512}
            height={512}
            quality={100}
          />
        </Half>

        <Half>
          <h2>Skins</h2>

          <p>
            In-game you'll find plenty of different ways to view the cube, from
            retro throwbacks to challenges like playing without numbers.
          </p>

          <p>
            If that's not enough, <Link href='/skins'><a>Custom Skins</a></Link> allow
            you to create, remix & upload your own texture packs for the game.
          </p>
        </Half>
      </Section>

      <Section>
        <Half>
          <h2>Compete for World Records</h2>

          <p>
            Mastermine features global leaderboards for a potentially infinite
            amount of cube sizes, alongside modes for challenging yourself, or
            just relaxing in the zen of solving a large cube.
          </p>

          <p>
            Compete with other players for the fastest times on small cubes you
            can retry in a matter of seconds, or race to own the largest cube on
            the board with puzzles that last over an hour!
          </p>
        </Half>

        <Half>
          <Image
            src='/img/8x8Gold.png'
            alt='A solid gold 8x8 cube'
            width={512}
            height={512}
            quality={100}
          />
        </Half>
      </Section>

      <Section>
        <Half>
          <Image
            src='/img/15x15Sonar.png'
            alt='A 15x15 cube using the Sonar powerup'
            width={512}
            height={512}
            quality={100}
          />
        </Half>

        <Half>
          <h2>An infinite roster of puzzles</h2>

          <p>
            If your device can handle it, Mastermine can play a cube of <em>any size.</em>
            The leaderboards already hold records up to 80x80x80 - that's over
            six and a half <em>thousand</em> mines to avoid.
          </p>

          <p>
            Even on the smallest of cubes, there are so many arrangements you'll
            never play the same game twice.
          </p>
        </Half>
      </Section>
    </>
  )
}

export default MainDescription
