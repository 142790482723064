import styled from 'styled-components'
import dynamic from 'next/dynamic'
import DescriptionSections from '../components/DescriptionSections'
import PlatformBadges from '../components/PlatformBadges'
import Footer from '../components/Footer'
import Menu from '../components/Menu'
import ErrorBoundary3d from '../components/ErrorBoundary3d'

const LogoHeading = styled.h1`
  font-family: Rubik, sans-serif;
  font-style: italic;
  font-weight: 700;
  text-align: center;
  margin: 0;

  position: absolute;
  font-size: 100px;
  left: 50%; top: 30px;
  transform: translateX(-50%);

  text-shadow: 1px 1px 0 rgb(236, 236, 236);

  @media (max-width: 700px) {
    font-size: 40px;
    top: 90px;
  }
`
const CoverContainer = styled.div`
  width: 100vw; height: 70vh;
  min-height: 600px;
  position: relative;
`
const BottomPinned = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
`

// We lazy load & don't SSR the 3D cube, which saves us from downloading
// ThreeJS during initial page load
const Cube3d = dynamic(
  () => import('../components/Cube3d'),
  { ssr: false, loading: () => <></> }
)

export default function Home () {
  return (
    <>
      <ErrorBoundary3d>
        <LogoHeading>MASTERMINE</LogoHeading>
        <CoverContainer>
          <Cube3d />

          <BottomPinned>
            <PlatformBadges />
          </BottomPinned>
        </CoverContainer>
      </ErrorBoundary3d>
      <Menu />

      <DescriptionSections />

      <Footer />
    </>
  )
}
